export class CheckupDetails {
  doctor: string;
  created_at: Date;
  updated_at: Date;
  next_checkup_date: Date;
  title: string;
  remarks: string;
  extraNotes: string;
  description: string;
  category: string;
  arrayDescription: any;
  reportURL: string;
  reportText: string;
  reports: [];
  lineNotes: string;
  total_price: number;
  medicineDetails: any;
  qty: number;
  _id: string;
}
