import { PetRequest } from './pet-request';

export class CustomerRegister {
  mobile_number: string;
  email_id: string;
  first_name: string;
  last_name: string;
  address: string;
  isPinPassed: boolean;
  pets: PetRequest[] = [];
}
