import { Pet, Customer, Payment, Doctor, Clinic } from '..';

export class InvoiceDetails {
  _id: string;
  channel: string;
  status: string;
  discount_percentage: number;
  discount: number;
  discount_name: string;
  total_amount: number;
  balance: number;
  payments_made: number;
  pet: Pet;
  doctor: Doctor;
  clinic: Clinic;
  checkup: any;
  customer: Customer;
  payments: Payment[];
  created_at: string;
  invoice_no: number;
  invoiceid: string;
  invoice_url: string;
  prescription_url: string;
  razorpay_order_id: string;
}
