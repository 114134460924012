import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../shared-module/constants';

@Injectable({
  providedIn: 'root'
})
export class ExtraService {
  constructor(private httpClient: HttpClient) {}
  activateDoctor(doctorID: string, status: string) {
    const body = {
      status
    };
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_ACTIVATE_API + doctorID, body);
  }

  resetPassword(resetCode: string, password: string) {
    const body = {
      password
    };
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.RESET_PASSWORD_API + resetCode, body);
  }
}
