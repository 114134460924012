<h1 mat-dialog-title class="text-center blueHeader dialogHeader text-white">Register New Customer</h1>
<mat-dialog-content class="mb-4">
  <div class="mt-3">
    <div class="col-md-12 mb-2">
      <label for="phone" class="toPay col-md-6 required">Phone No</label>
      <input
        type="text"
        class="form-control input-font d-inline col-md-6"
        id="phone"
        (keyup)="changeValue()"
        maxlength="10"
        inputText
        allowType="ONLYDIGITS"
        placeholder="Enter Phone No"
        [(ngModel)]="phone"
      />
    </div>
    <div class="col-md-12 mb-2">
      <label for="fName" class="toPay col-md-6 required">First Name</label>
      <input
        type="text"
        class="form-control input-font d-inline col-md-6"
        id="fName"
        placeholder="Enter First Name"
        (keyup)="changeValue()"
        maxlength="50"
        inputText
        allowType="ONLYTEXTS"
        [(ngModel)]="fName"
      />
    </div>
    <div class="col-md-12 mb-2">
      <label for="lName" class="toPay col-md-6">Last Name</label>
      <input
        type="text"
        class="form-control input-font d-inline col-md-6"
        id="lName"
        placeholder="Enter Last Name"
        (keyup)="changeValue()"
        [(ngModel)]="lName"
        maxlength="50"
        inputText
        allowType="ONLYTEXTS"
      />
    </div>
    <div class="col-md-12 mb-2">
      <label for="email" class="toPay col-md-6">Email Id</label>
      <input
        type="text"
        class="form-control input-font d-inline col-md-6"
        id="email"
        maxlength="60"
        inputText
        allowType="TEXTEMAIL"
        placeholder="Enter Email Id"
        [(ngModel)]="email"
      />
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button mat-button class="mat-raised-button mr-4 btn blankTurquoise btnWidth" (click)="dialogRef.close(false)">
      Cancel
    </button>
    <button
      mat-button
      class="mat-raised-button btn turquoiseBtn btnWidth btn-sm updateButton"
      (click)="confirmCustomer()"
      [disabled]="disableConfirm ? true : null"
    >
      Confirm
    </button>
  </div>
</mat-dialog-actions>
