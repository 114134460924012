import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CommonService } from 'src/app/shared-module/common-service.service';
import { Constants } from 'src/app/shared-module/constants';
import { GlobalResponse, IHSN } from 'src/app/shared-module/models';

@Component({
  selector: 'app-update-hsn-dialog',
  templateUrl: './update-hsn-dialog.component.html',
  styleUrls: []
})
export class UpdateHSNDialogComponent implements OnInit {
  hsnList: IHSN[] = [];
  hsn_selected: IHSN = {} as IHSN;

  constructor(
    public dialogRef: MatDialogRef<UpdateHSNDialogComponent>,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.commonService.fetchHSNList(0, 9999).subscribe((res: GlobalResponse) => {
      if (res && res.message === Constants.STATUS_SUCCESS && res.result) {
        this.hsnList = res.result.hsn;
      }
    });
  }
}
