export class MedicineHistory {
  _id: string;
  timings: {
    morning: number;
    afternoon: number;
    night: number;
  };
  name: string;
  price: number;
  qty: number;
  total_price: number;
  consume_days: number;
  visible: boolean;
  drug: string;
  checkup: string;
  clinic: string;
  doctor: string;
  pet: string;
  customer: string;
  created_at: string;
}
