<nav class="navbar navbar-expand-md navbar-dark header-content d-flex align-items-center">
  <a class="navbar-brand col-md-5 col-5" routerLink="/pet">
    <img alt="PawsNme" src="../../../assets/images/Logo white option_380_100.svg" class="logo-header" />
    <span
      class="clinic-name d-none d-sm-inline-block pl-4"
      *ngIf="doctor && doctor.clinics && doctor.clinics[0] && doctor.clinics[0].clinic"
      >{{ doctor?.clinics[0]?.clinic?.name }}</span
    >
  </a>
  <div class="right-cta ml-auto">
    <section
      class="rightButtons d-flex align-items-center"
      title="Switch to let patients know your on-call availability"
    >
      <!-- <span class="switchToggle d-none" *ngIf="isLoggedIn">
        <span class="textSwitch">In Clinic</span>
        <input type="checkbox" id="clinicToggle" [(ngModel)]="clinicToggle" (change)="toggleDoctor()">
        <label for="clinicToggle"></label>
      </span> -->
      <!-- <span class="switchToggle" *ngIf="isLoggedIn">
        <span class="textSwitch">CALL</span>
        <input type="checkbox" id="phoneToggle" [(ngModel)]="phoneToggle" (change)="toggleDoctor('phone')" />
        <label for="phoneToggle"></label>
      </span> -->
      <button mat-icon-button (click)="toggleFullScreen()" class="btn full-screen-button">
        <mat-icon>{{ fullScreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
        {{ fullScreen ? 'Exit Full Screen' : 'Full Screen' }}
      </button>
    </section>
    <section class="doctorList d-inline-block float-right mr-md-2 mr-sm-1" *ngIf="isLoggedIn">
      <div class="btn-group">
        <input
          type="button"
          class="btn fa-sort-desc dropdown-toggle docBtn"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          value="{{ action }}"
          *ngIf="action && action.length > 0"
        />
        <ul class="dropdown-menu" *ngIf="staffFlag">
          <ng-container *ngFor="let item of headerDoctorList">
            <li
              class="dropdown-item"
              (click)="doctorChanged(item)"
              *ngIf="item.name !== 'All' && item.status === 'ACTIVE'"
            >
              {{ item?.name }}
            </li>
          </ng-container>
        </ul>
      </div>
    </section>
  </div>
</nav>
