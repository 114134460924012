<h1
  mat-dialog-title
  class="text-center dialogHeader text-white"
  [ngClass]="headerClass ? 'blueHeader' : 'warningHeader'"
>
  {{ headerText }}
</h1>
<mat-dialog-content class="mb-4 text-center message">
  <p>{{ confirmMessage }}</p>
  <p *ngIf="confirmMessage1">{{ confirmMessage1 }}</p>
  <p *ngIf="confirmMessage2" class="amount">{{ confirmMessage2 }}</p>
  <section *ngIf="confirmCheckbox" class="text-center">
    <input type="checkbox" id="checkboxSelection" [(ngModel)]="checkboxSelection" />
    <label for="checkboxSelection text-info" class="checkboxSelection ml-2">{{ confirmCheckboxLabel }}</label>
  </section>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button
      mat-button
      class="mat-raised-button btn blankTurquoise mr-4 btnWidth"
      (click)="dialogRef.close(false)"
      *ngIf="showCancelButton"
    >
      {{ cancelButton }}
    </button>
    <button
      mat-button
      class="mat-raised-button btn turquoiseBtn btnWidth"
      (click)="confirmAction()"
      *ngIf="showSuccessButton"
    >
      {{ successButton }}
    </button>
  </div>
</mat-dialog-actions>
