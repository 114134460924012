import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Observer } from 'rxjs';
import { CommonService } from '../common-service.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Constants } from '../constants';

@Component({
  selector: 'app-camera-dialog',
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.scss']
})
export class CameraDialogComponent {
  // latest snapshot
  public webcamImage: WebcamImage = null;
  pictureTaken = false;
  imageFile: File = {} as File;
  imageBlob: any = {} as any;
  base64TrimmedURL: string;

  constructor(
    public dialogRef: MatDialogRef<CameraDialogComponent>,
    private toastr: ToastrService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    this.imageBlob = webcamImage.imageAsDataUrl;
    this.pictureTaken = true;
    this.getImage(webcamImage.imageAsDataUrl);
    this.toastr.success('Image has been captured');
  }

  getImage(imageUrl: string) {
    this.getBase64ImageFromURL(imageUrl).subscribe((base64Data: string) => {
      this.base64TrimmedURL = base64Data;
      this.createBlobImageFileAndShow();
    });
  }
  /* Method to fetch image from Url */
  getBase64ImageFromURL(url: any): Observable<string> {
    // tslint:disable: deprecation
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = value => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  /* Method to create base64Data Url from fetched image */
  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    const dataURL: string = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

  /* Method to convert Base64Data Url as Image Blob */
  dataURItoBlob(dataURI: string): Observable<Blob> {
    return Observable.create((observer: Observer<Blob>) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: 'image/jpeg' });
      observer.next(blob);
      observer.complete();
    });
  }

  createBlobImageFileAndShow(): void {
    this.dataURItoBlob(this.base64TrimmedURL).subscribe((blob: Blob) => {
      this.imageBlob = blob;
      const imageName: string = this.commonService.generateFileName(Constants.FILE_NAME_PET, Constants.JPEG_EXTENSION);
      this.imageFile = new File([this.imageBlob], imageName, {
        type: 'image/jpeg'
      });
    });
  }

  close(type) {
    if (type && this.pictureTaken) {
      // this.imageFile = new File([this.imageBlob], this.generateFileName(), { type: 'image/png' });
      // saveAs(this.imageBlob, 'abc.png');
      this.dialogRef.close(this.imageFile);
    } else {
      const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true
      });
      confirmDialogRef.componentInstance.confirmMessage = Constants.CONFIRMATION_WITHOUT_PICTURE;
      confirmDialogRef.componentInstance.cancelButton = 'No';
      confirmDialogRef.componentInstance.successButton = 'Yes';

      confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close(false);
        } else {
          confirmDialogRef.close();
        }
      });
    }
  }
}
