import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeIn from '@angular/common/locales/en-IN';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThemeService } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExtraModule } from './extra-module/extra-module.module';
import { NonSecureModule } from './non-secure-pages/non-secure.module';
import { CheckupService } from './shared-module/services/checkup.service';
import { DoctorService } from './shared-module/services/doctor.service';
import { RouterExtService } from './shared-module/services/router-ext.service';
registerLocaleData(localeIn);

@NgModule({
  declarations: [AppComponent],
  imports: [ExtraModule, AppRoutingModule, HttpClientModule, BrowserModule, NonSecureModule, BrowserAnimationsModule],
  providers: [DoctorService, CheckupService, Title, ThemeService, RouterExtService],
  bootstrap: [AppComponent]
})
export class AppModule {}
