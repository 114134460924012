<div class="content">
  <div class="bg-content">
    <div>
      <img class="text-left logo" alt="Pawsnme" src="./assets/images/Logo white option_380_100.svg" />
    </div>
    <div class="text-center content-padding">
      <p class="content-title">
        <b>Building a better world <br />for man's best friend</b>
      </p>
      <p class="content-text pt-1">Get our vet solution if you are a vet doctor</p>
      <button type="button" class="btn app-download-btn" (click)="login()">Vet Solution</button>

      <p class="content-text pt-5">
        Download PawsNme app to keep track of your pet's health record <br />and manage all it's other needs
      </p>

      <p>
        <span class="app-download-btn">Android App </span><span class="download-btn-spacing"></span
        ><span class="app-download-btn">iOS App</span>
      </p>
    </div>
  </div>
</div>
<app-footer></app-footer>
