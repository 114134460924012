import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html'
})
export class UnderConstructionComponent implements OnInit {
  isLoggedIn = true;
  constructor() {}

  ngOnInit() {}
}
