import { NgModule } from '@angular/core';
import { AuthService } from './auth-intercept/auth.service';
import { AuthGuardService } from './auth-intercept/auth-guard.service';
import { AuthenticationService } from '../shared-module/services/authentication.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './auth-intercept/request.interceptor';
import { ErrorInterceptor } from './auth-intercept/error.interceptor';
import { SharedModule } from '../shared-module/shared-module.module';
import { ExtraRoutingModule } from './extra-routing.module';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { ActivateDoctorComponent } from './activate-doctor/activate-doctor.component';
import { ExtraService } from './extra.service';
import { SupportComponent } from './support/support.component';
import { ResetPasswordComponent } from './reset-passoword/reset-passoword.component';

@NgModule({
  declarations: [UnderConstructionComponent, ActivateDoctorComponent, SupportComponent, ResetPasswordComponent],
  imports: [SharedModule, ExtraRoutingModule],
  providers: [
    AuthService,
    AuthGuardService,
    ExtraService,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  exports: []
})
export class ExtraModule {}
