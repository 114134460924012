export class Note {
  _id: string;
  title: string;
  pet_findings_status: string;
  pet_weight: number;
  pet_fluid_intake: number;
  visible: boolean;
  line_of_treatments: any[];
  created_by: string;
  updated_by: string;
  checkup: string;
  doctor: {
    _id: string;
    first_name: string;
    last_name: string;
    salutation: string;
  };
  clinic: {
    _id: string;
    name: string;
  };
  pet: {
    _id: string;
    name: string;
  };
  updated_at: string;
  created_at: string;
}
