import { Pet, Customer, Payment } from '..';

export class Invoice {
  _id: string;
  channel: string;
  status: string;
  discount_percentage: number;
  discount: number;
  discount_name: string;
  total_amount: number;
  balance: number;
  payments_made: number;
  pet: Pet;
  customer: Customer;
  payments: Payment[];
  created_at: string;
  invoice_no: number;
  invoiceid: string;
  checkup: any;
  invoice_url: string;
  prescription_url: string;
  razorpay_order_id: string;
}
