import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Constants } from './shared-module/constants';
declare var jQuery: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title: string;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private titleService: Title,
    private router: Router,
    location: PlatformLocation,
    private activatedRoute: ActivatedRoute
  ) {
    Constants.MAIN_ENV_URL = environment.apiUrl;
    location.onPopState(() => {
      history.forward();
    });
  }

  ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data.title) {
            return child.snapshot.data.title;
          }
          return appTitle;
        })
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.blockUI.start(Constants.LOADER_LOADING);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.blockUI.stop();
      }
    });
  }

  setDocTitle(title: string) {
    this.titleService.setTitle(title);
  }
}
