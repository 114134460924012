import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SupportComponent } from '../extra-module/support/support.component';
import { MainLayoutNonLoginComponent } from '../shared-module/main-layout-non-login/main-layout-nonlgn.component';
import { ActivateDoctorComponent } from './activate-doctor/activate-doctor.component';
import { ResetPasswordComponent } from './reset-passoword/reset-passoword.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';

const routes: Routes = [
  { path: 'doctor/activate/:doctorID', component: ActivateDoctorComponent },
  { path: 'setting/resetCode/:resetCode', component: ResetPasswordComponent },
  {
    path: 'extra',
    component: MainLayoutNonLoginComponent,
    children: [
      {
        path: 'underconstruction',
        component: UnderConstructionComponent,
        data: { title: 'Under Construction', enableExtraHeader: false }
      },
      { path: '', redirectTo: 'underconstruction', pathMatch: 'full' },
      { path: 'support', component: SupportComponent, data: { title: 'Support', enableExtraHeader: false } }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExtraRoutingModule {}
