<h1 mat-dialog-title class="text-center blueHeader dialogHeader text-white">Take Picture</h1>
<mat-dialog-content class="mb-4">
  <app-camera (pictureTaken)="handleImage($event)" *ngIf="!pictureTaken"></app-camera>

  <div class="snapshot" *ngIf="pictureTaken">
    <img [src]="webcamImage.imageAsDataUrl" alt="" />
  </div>

  <div class="retry" *ngIf="pictureTaken">
    <button type="button" class="btn btn-success" (click)="pictureTaken = false" title="Retry">
      <span class="fa fa-refresh"></span>
    </button>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button mat-button class="mat-raised-button mr-4 btn blankTurquoise btnWidth" (click)="dialogRef.close(false)">
      Cancel
    </button>
    <button mat-button class="mat-raised-button btn turquoiseBtn btnWidth" (click)="close(true)">Confirm</button>
  </div>
</mat-dialog-actions>
