import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService } from '../../shared-module/common-service.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private commonService: CommonService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.commonService.doctorValue) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.commonService.tokenValue}`
        },
        withCredentials: true
      });
    }

    if (request.url.indexOf('upload') > -1) {
      request = request.clone({
        setHeaders: {},
        withCredentials: true
      });
      return next.handle(request);
    } else {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });

      return next.handle(request);
    }
  }
}
