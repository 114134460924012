export class Vaccination {
  _id: string;
  name: string;
  qty: number;
  price: number;
  visible: boolean;
  next_at: string;
  drug: string;
  checkup: string;
  clinic: string;
  doctor: string;
  pet: string;
  customer: string;
}
