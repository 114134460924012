import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Doctor, Drug } from '../models';

@Component({
  selector: 'app-barcode-item-selection-dialog',
  templateUrl: './barcode-item-selection-dialog.component.html',
  styleUrls: ['./barcode-item-selection-dialog.component.scss']
})
export class BarcodeItemSelectionDialogComponent implements OnInit {
  public doctor: Doctor;
  public drugId: string;
  itemList: Drug[] = [];
  rowData: any[] = [];
  current_item: Drug;
  barcode: any;
  item_selected: any;
  po_no: any;
  qty: any;
  price: any;
  headerTitle = 'Select Item';
  @BlockUI() blockUI: NgBlockUI;

  constructor(public dialogRef: MatDialogRef<BarcodeItemSelectionDialogComponent>) {}

  ngOnInit() {
    this.current_item = this.itemList[0];
    this.item_selected = this.current_item._id;
    this.qty = this.current_item.qty;
    this.price = this.current_item.price;
    for (const batchItem of this.itemList) {
      for (const item of this.rowData) {
        if (item.batch === batchItem._id && item._id !== this.current_item._id) {
          batchItem.qty -= item.qty;
          break;
        }
      }
    }
  }

  radioChanged(item: any) {
    this.current_item = item;
  }

  @HostListener('document:keydown', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    this.onKeydown(event);
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.dialogRef.close(this.current_item);
    }
  }
}
