import { Breed } from './breed';
import { Customer } from '../common';

export class PetDetails {
  name: string;
  dob: string;
  profile_image_url: string;
  birth_certificate_url: string;
  color: string;
  desexing: string;
  _id: string;
  species: string;
  sex: string;
  breed: Breed;
  customer: Customer;
  updated_at: string;
  created_at: string;
  pet_no: number;
  family_planning: string;
  petid: string;
  id: string;
}
