<h1 mat-dialog-title class="text-center blueHeader dialogHeader text-white">Update HSN</h1>
<mat-dialog-content class="mb-4">
  <div>
    <div class="col-md-12 mb-2">
      <table class="table table-hover table-bordered billingTable">
        <thead class="turTable">
          <tr class="">
            <th class="cellHeader text-center"></th>
            <th class="cellHeader text-center">Code</th>
            <th class="cellHeader text-center">Description</th>
          </tr>
        </thead>
        <tbody *ngIf="hsnList && hsnList.length > 0">
          <ng-container *ngFor="let item of hsnList; let index = index">
            <tr>
              <td class="cellValue text-center">
                <input type="radio" name="hsn" [(ngModel)]="hsn_selected" value="{{ item._id }}" />
              </td>
              <td class="cellValue text-center">{{ item.code }}</td>
              <td class="cellValue">{{ item?.description }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button mat-button class="mat-raised-button btn mr-4 blankTurquoise btnWidth" (click)="dialogRef.close(false)">
      Cancel
    </button>
    <button
      mat-button
      class="mat-raised-button btn turquoiseBtn btnWidth btn-sm updateButton"
      (click)="dialogRef.close(hsn_selected)"
    >
      Select HSN
    </button>
  </div>
</mat-dialog-actions>
