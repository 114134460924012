<!-- <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="dialogRef.close(false)">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button> -->

<h1 mat-dialog-title class="text-center dialogHeader blueHeader">{{ headerTitle }}</h1>
<mat-dialog-content class="mb-4">
  <div class="text-center">
    <!-- <a [class.d-none]="fileUploaded && !multipleFiles && fileUploaded.length == 1"
      (click)="(!multipleFiles && fileUploaded && fileUploaded.length == 1)?null:uploadFile()" class="upload-icon-link"
      href="javascript:void(0);">
      <em class="icon-upload-new"
        [ngClass]="{'disabled': !multipleFiles && fileUploaded && fileUploaded.length == 1}"></em>
      <span>Upload Attachment</span>
    </a> -->
    <button (click)="uploadFile()" class="btn blueButtonAdd wid fa fa-cloud-upload" title="{{ uploadButtonTitle }}">
      {{ uploadButtonText }}
    </button>
    <em class="icon-upload-new" [ngClass]="{ disabled: multipleFiles && fileUploaded }"></em>
    <input
      type="file"
      id="uploadBtn"
      (change)="selectFile($event)"
      accept="{{ fileTypeAccepted }}"
      multiple
      *ngIf="multipleFiles"
      class="d-none"
    />
    <input
      type="file"
      id="uploadBtn"
      (change)="selectFile($event)"
      accept="{{ fileTypeAccepted }}"
      *ngIf="!multipleFiles"
      class="d-none"
    />
  </div>

  <div *ngIf="isFileUploaded" style="margin-top: 8px">
    <div class="attachment-info" *ngFor="let file of fileUploaded; let i = index">
      <div class="file-name col-md-12">
        <span id="filename">{{ file.fileName }}</span>
        <span class="file-size">({{ file.fileSize | number: '1.2-2' }} MB)</span>
        <span class="text-right col-md-5">
          <a href="javascript:void(0);" class="text-danger" (click)="removeFile(file)">
            <span> </span>
            <span>Remove Attachment</span>
          </a>
        </span>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button
      mat-button
      class="btn btnWidth mr-4 blankTurquoise"
      (click)="cancelClicked()"
      title="{{ cancelButtonTitle }}"
    >
      Cancel
    </button>
    <button
      mat-button
      class="btn btnWidth turquoiseBtn"
      (click)="onUploadFiles(fileUploaded)"
      [disabled]="isDisable"
      title="{{ submitButtonTitle }}"
    >
      Upload
    </button>
  </div>
</mat-dialog-actions>
<mat-dialog-content class="mb-4">
  <div *ngIf="uploadedFiles && uploadedFiles.length > 0" class="border-top border-primary mt-2">
    <u class="mb-1 uploadedText">Already Uploaded Reports</u>
    <div class="attachment-info text-center" *ngFor="let file of uploadedFiles; let i = index">
      <div class="file-name col-md-12">
        <span id="filename">
          <a href="{{ file?.url }}" target="_blank">
            <span> </span>
            <span>{{ file?.file_name }}</span>
          </a></span
        >
        <span class="text-right col-md-5">
          <a href="javascript:void(0);" class="text-danger" (click)="deleteFile(file)">
            <span> </span>
            <span>Delete Attachment</span>
          </a>
        </span>
      </div>
    </div>
  </div>
</mat-dialog-content>
