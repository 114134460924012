<h1 mat-dialog-title class="text-center blueHeader dialogHeader text-white">{{ headerTitle }}</h1>
<mat-dialog-content class="mb-4">
  <div>
    <h6 class="pl-3">BarCode: {{ barcode }}</h6>
    <div class="col-md-12 mb-2">
      <table class="table table-hover table-bordered billingTable">
        <thead class="turTable">
          <tr class="">
            <th class="cellHeader text-center"></th>
            <th class="cellHeader text-center">Name</th>
            <th class="cellHeader text-center">Category</th>
            <th class="cellHeader text-center">Available Qty</th>
            <th class="cellHeader text-center">Unit Price</th>
          </tr>
        </thead>
        <tbody *ngIf="itemList && itemList.length > 0">
          <ng-container *ngFor="let item of itemList; let index = index">
            <tr *ngIf="item.qty > 0">
              <td class="cellValue text-center">
                <input
                  type="radio"
                  name="batch"
                  [(ngModel)]="item_selected"
                  value="{{ item._id }}"
                  (click)="radioChanged(item)"
                />
              </td>
              <td class="cellValue">{{ item?.name }}</td>
              <td class="cellValue">{{ item?.category }}</td>
              <td class="cellValue text-right">{{ item?.qty }}</td>
              <td class="cellValue text-right">
                {{ item?.selling_price | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button mat-button class="mat-raised-button btn mr-4 blankTurquoise btnWidth" (click)="dialogRef.close(false)">
      Cancel
    </button>
    <button
      mat-button
      class="mat-raised-button btn turquoiseBtn btnWidth btn-sm updateButton"
      (click)="dialogRef.close(current_item)"
      (keyup.enter)="dialogRef.close(current_item)"
    >
      Select Item
    </button>
  </div>
</mat-dialog-actions>
