<h1 mat-dialog-title class="text-center blueHeader dialogHeader text-white">{{ headerTitle }}</h1>
<mat-dialog-content class="mb-4">
  <div class="col-md-12 mb-2">
    <label for="qty" class="payingClinic col-md-4">Name</label>
    <label for="qty" class="payingClinic col-md-8">{{ itemName }}</label>
  </div>
  <div class="col-md-12 mb-2" *ngIf="modelType === 'qty'">
    <label for="qty" class="payingClinic col-md-4 required">Qty</label>
    <input
      type="text"
      inputText
      allowType="DIGITSWTHDOT"
      id="qty"
      [(ngModel)]="qty"
      (blur)="onValueChange()"
      class="form-control d-inline-block input-font name inputBox col-md-8"
    />
  </div>
  <div class="col-md-12 mb-2" *ngIf="modelType === 'tax'">
    <label for="tax" class="payingClinic col-md-4 required">Tax</label>
    <select name="tax" id="tax" class="form-control input-font category inputBox col-md-8" [(ngModel)]="tax">
      <option value="0">0</option>
      <option value="5">5</option>
      <option value="12">12</option>
      <option value="18">18</option>
      <option value="28">28</option>
    </select>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button mat-button class="mat-raised-button btn mr-4 blankTurquoise btnWidth" (click)="dialogRef.close(false)">
      Cancel
    </button>
    <button mat-button class="mat-raised-button btn turquoiseBtn btnWidth btn-sm updateButton" (click)="confirm()">
      Confirm
    </button>
  </div>
</mat-dialog-actions>
