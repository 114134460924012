<div class="content">
  <div class="bg-content">
    <div>
      <img class="text-left logo" alt="Pawsnme" src="./assets/images/Logo white option_380_100.svg" />
    </div>
    <div class="text-center content-padding">
      <p class="content-title pt-5"><b>No Page Found</b></p>
      <p class="content-text pt-5">
        Naivate to&nbsp;&nbsp;<a class="view app-download-btn" [routerLink]="['/home']" title="Home Page"> Home </a
        >&nbsp;&nbsp;Page
      </p>
      <p class="content-text pt-3 pb-5">
        Naivate to&nbsp;&nbsp;<a class="view app-download-btn" [routerLink]="['/login']" title="Login Page"> Login </a
        >&nbsp;&nbsp;Page
      </p>

      <p class="content-text pt-5">
        Download PawsNme app to keep track of your pet's health record <br />and manage all it's other needs
      </p>

      <p>
        <span class="app-download-btn">Android App </span><span class="download-btn-spacing"></span
        ><span class="app-download-btn">iOS App</span>
      </p>
    </div>
  </div>
</div>
<app-footer></app-footer>
