import { Doctor } from '../common/doctor';

export class LoginResponse {
  http_code: string;
  message: string;
  result: {
    doctor: Doctor;
    token: string;
  };
}
