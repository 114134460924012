import { Component, OnInit } from '@angular/core';
import { ExtraService } from '../extra.service';
import { GlobalResponse } from '../../shared-module/models';
import { Constants } from '../../shared-module/constants';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogComponent } from '../../shared-module/confirmation-dialog/confirmation-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { CommonService } from '../../shared-module/common-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-activate-doctor',
  templateUrl: './activate-doctor.component.html',
  styleUrls: ['./activate-doctor.component.scss']
})
export class ActivateDoctorComponent implements OnInit {
  isLoggedIn = false;
  doctorID: string;
  confirmDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private adminService: ExtraService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public toastr: ToastrService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.doctorID = this.route.snapshot.paramMap.get('doctorID');
  }

  activateDoctor() {
    if (this.doctorID && this.doctorID.length > 5) {
      this.blockUI.start();
      this.adminService.activateDoctor(this.doctorID, Constants.STATUS_ACCEPT).subscribe(
        (res: GlobalResponse) => {
          this.blockUI.stop();
          if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
            this.commonService.openStatusDialog('Doctor Activated Successfully', Constants.HOME_URL);
          }
        },
        (error: any) => {
          this.blockUI.stop();
          this.toastr.error(error);
        }
      );
    }
  }

  rejectRegistration() {
    if (this.doctorID && this.doctorID.length > 5) {
      this.blockUI.start();
      this.adminService.activateDoctor(this.doctorID, Constants.STATUS_REJECT).subscribe(
        (res: GlobalResponse) => {
          this.blockUI.stop();
          if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
            this.commonService.openStatusDialog('Doctor Registration Rejected', Constants.HOME_URL);
          }
        },
        (error: any) => {
          this.blockUI.stop();
          this.toastr.error(error);
        }
      );
    }
  }

  openConfirmationDialog() {
    this.confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure to reject the Registration?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.rejectRegistration();
      }
      this.confirmDialogRef = null;
    });
  }
}
