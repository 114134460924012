import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}

  public confirmMessage: string;
  public confirmMessage1: string;
  public confirmMessage2: string;
  public headerText = 'Confirm';
  public successButton = 'Confirm';
  public cancelButton = 'Cancel';
  public headerClass = true;
  public confirmCheckbox = false;
  public confirmCheckboxLabel = '';
  public checkboxSelection = false;
  showSuccessButton = true;
  showCancelButton = true;

  confirmAction() {
    if (this.checkboxSelection && this.confirmCheckbox) {
      this.dialogRef.close('doaction');
    } else {
      this.dialogRef.close(true);
    }
  }
}
