import { CustomerStore } from './customer-store';
import { POMedicine } from './po-medicine';

export class PODetails {
  _id: string;
  doctor: string;
  clinic: string;
  status: string;
  invoice_no: string;
  inovice_url: string;
  phonenumber: string;
  finalPrice: number;
  discount: number;
  PO: string;
  customer: CustomerStore;
  medicine: POMedicine[];
}
