import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../common-service.service';
import { Constants } from '../constants';
import { GlobalResponse } from '../models';

@Component({
  selector: 'app-advance-payment-dialog',
  templateUrl: './advance-payment-dialog.component.html',
  styleUrls: ['./advance-payment-dialog.component.scss']
})
export class AdvancePaymentDialogComponent {
  payment = Constants.PAYMENT_CASH;
  public paymentAmount = 0;
  public checkupId: string;
  public paymentId: string;
  public confirmMessage: string;
  public startPoint: string;
  public confirmButtonText = 'Confirm';
  public cancelButtonText = 'Cancel';
  public pageTitle = 'Add Advance Payment';
  public editFlag = false;
  public headerClass = true;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public dialogRef: MatDialogRef<AdvancePaymentDialogComponent>,
    private commonService: CommonService,
    private toastr: ToastrService
  ) {}

  confirmPayment() {
    if ((this.payment && this.paymentAmount) || this.paymentAmount >= 0) {
      this.blockUI.start();
      let req_obj = {};

      if (this.editFlag) {
        req_obj = {
          amount: this.paymentAmount,
          paymentId: this.paymentId
        };
        this.commonService.updateAdvancePayment(req_obj, this.checkupId).subscribe(
          (res: GlobalResponse) => {
            this.blockUI.stop();
            if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
              this.dialogRef.close(true);
            }
          },
          error => {
            this.blockUI.stop();
            this.dialogRef.close(false);
            this.toastr.error(error.message);
          }
        );
      } else {
        req_obj = {
          amount: this.paymentAmount,
          payment_method: this.payment
        };
        this.commonService.addAdvancePayment(req_obj, this.checkupId).subscribe(
          (res: GlobalResponse) => {
            this.blockUI.stop();
            if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
              this.dialogRef.close(true);
            }
          },
          error => {
            this.blockUI.stop();
            this.dialogRef.close(false);
            this.toastr.error(error.message);
          }
        );
      }
    } else {
      this.toastr.error(Constants.ERROR_CHOOSE_PAYMENT_AMOUNT);
    }
  }
}
