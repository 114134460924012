<h1
  mat-dialog-title
  class="text-center dialogHeader text-white"
  [ngClass]="headerClass ? 'blueHeader' : 'warningHeader'"
>
  {{ pageTitle }}
</h1>
<mat-dialog-content class="mb-4">
  <div class="paymentType text-center">
    <p *ngIf="confirmMessage && confirmMessage.length > 1">{{ confirmMessage }}</p>
    <div class="col-md-12 mb-2">
      <span class="required col-md-6">Payment Option: &nbsp;</span>
      <div class="custom-control custom-radio custom-control-inline col-md-2">
        <input
          type="radio"
          [(ngModel)]="payment"
          value="CASH"
          id="cashA"
          name="payment"
          class="custom-control-input"
          [disabled]="editFlag ? true : null"
        />
        <label class="custom-control-label" for="cashA">Cash</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline col-md-2">
        <input
          type="radio"
          [(ngModel)]="payment"
          value="CARD"
          id="cardA"
          name="payment"
          class="custom-control-input"
          [disabled]="editFlag ? true : null"
        />
        <label class="custom-control-label" for="cardA">Card</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline col-md-2">
        <input
          type="radio"
          [(ngModel)]="payment"
          value="UPI"
          id="upiA"
          name="payment"
          class="custom-control-input"
          [disabled]="editFlag ? true : null"
        />
        <label class="custom-control-label" for="upiA">UPI</label>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div class="paymentDiv col-md-12 mb-2">
      <label for="paymentAmount" class="payingClinic col-md-5 required col-sm-6">Paying(&#8377;) in Clinic</label>
      <input
        type="text"
        inputText
        allowType="DIGITSWTHDOT"
        class="form-control input-font paymentAmount discountBox col-md-4 col-sm-6"
        id="paymentAmount"
        [(ngModel)]="paymentAmount"
        [disabled]="startPoint && startPoint === 'editBill' ? true : null"
      />
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button
      mat-button
      class="mat-raised-button mr-4 btn blankTurquoise btnWidth"
      (click)="dialogRef.close(false)"
      title="Click to cancel payment"
    >
      {{ cancelButtonText }}
    </button>
    <button
      mat-button
      class="mat-raised-button btn turquoiseBtn btnWidth"
      (click)="confirmPayment()"
      [disabled]="paymentAmount <= 0 && startPoint !== 'editBill' ? true : null"
      title="Click to confirm payment"
    >
      {{ confirmButtonText }}
    </button>
  </div>
</mat-dialog-actions>
