<app-header [isLoggedIn]="isLoggedIn"></app-header>
<div class="mainBox row">
  <p class="header col-md-12">Enter new Password and confirm</p>
  <div class="col-md-12 row">
    <div class="offset-md-3 col-md-2">
      <label for="newPasswordInput" class="mt-2">New Password</label>
    </div>
    <div class="col-md-4">
      <label
        for="newPasswordInput"
        class="fa fa-eye-slash eye"
        [ngClass]="{ 'fa-eye-slash': showNewPassword, 'fa-eye': !showNewPassword }"
        (click)="
          newPasswordInput.type = newPasswordInput.type == 'password' ? 'text' : 'password';
          showNewPassword = !showNewPassword
        "
      ></label>
      <input
        type="password"
        [(ngModel)]="newPassword"
        [ngModelOptions]="{ standalone: true }"
        class="form-control ml-3"
        id="newPasswordInput"
        #newPasswordInput
        placeholder="New Password"
        maxlength="20"
      />
    </div>
  </div>
  <div class="col-md-12 row mt-3">
    <div class="offset-md-3 col-md-2">
      <label for="confirmPasswordInput" class="mt-2">Confirm Password</label>
    </div>
    <div class="col-md-4">
      <label
        for="confirmPasswordInput"
        class="fa fa-eye-slash eye"
        [ngClass]="{ 'fa-eye-slash': shownConfirmPassword, 'fa-eye': !shownConfirmPassword }"
        (click)="
          confirmPasswordInput.type = confirmPasswordInput.type == 'password' ? 'text' : 'password';
          shownConfirmPassword = !shownConfirmPassword
        "
      ></label>
      <input
        type="password"
        [(ngModel)]="confirmPassword"
        [ngModelOptions]="{ standalone: true }"
        maxlength="20"
        class="form-control ml-3"
        id="confirmPasswordInput"
        #confirmPasswordInput
        placeholder="Confirm Password"
      />
    </div>
  </div>
  <div class="col-md-12 text-center mt-5">
    <button class="btn btn-success" (click)="resetPassword()">Reset Password</button>
    <!-- <button class="btn btn-secondary mr-2" (click)="openConfirmationDialog()">Reject</button> -->
  </div>
</div>
