export class Medicine {
  timingsMorning: number;
  timingsNoon: number;
  timingsNight: number;
  name: string;
  qty: number;
  remaining_quantity?: number;
  days: number;
  price: number;
  remarks: string;
  category: string;
  unit: string;
  addDosage: boolean;
  addInstruction: boolean;
  _id: string;
}
