import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../shared-module/common-service.service';
import { Constants } from '../../shared-module/constants';
import { GlobalResponse } from '../../shared-module/models';
import { ExtraService } from '../extra.service';

@Component({
  selector: 'app-reset-passoword',
  templateUrl: './reset-passoword.component.html',
  styleUrls: ['./reset-passoword.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  isLoggedIn = false;
  resetCode: string;
  newPassword = '';
  confirmPassword = '';
  showNewPassword = false;
  shownConfirmPassword = false;

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private adminService: ExtraService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.resetCode = this.route.snapshot.paramMap.get('resetCode');
  }

  resetPassword() {
    if (this.resetCode && this.resetCode.length > 5 && this.newPassword.length > 6 && this.confirmPassword.length > 6) {
      if (this.newPassword === this.confirmPassword) {
        this.blockUI.start();
        this.adminService.resetPassword(this.resetCode, this.newPassword).subscribe(
          (res: GlobalResponse) => {
            this.blockUI.stop();
            if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
              this.commonService.openStatusDialog('Password Resetted Successfully', Constants.LOGIN_URL);
            }
          },
          error => {
            this.blockUI.stop();
            this.toastr.error('Password reset failed');
          }
        );
      } else {
        this.toastr.error('Confirm Password doesnot match with New Password');
      }
    } else {
      this.toastr.error('Password length must be greater than 6 characters');
    }
  }
}
