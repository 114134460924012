import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Constants } from '../../shared-module/constants';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.isAuthenticated()) {
      if (!next || !next.data || this.auth.isAuthorized(next.data)) {
        return true;
      } else {
        this.router.navigate([Constants.PET_DASHBOARD_URL]);
        return false;
      }
    } else {
      this.router.navigate([Constants.LOGIN_URL], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
