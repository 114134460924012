export class DrugRequest {
  name: string;
  category: string;
  qty: number;
  mrp: number;
  hsnId: string;
  buying_price: number;
  selling_price: number;
  expiry_period: number;
  manufacturer: string;
  drugId: string;
  custom_batch: string;
  barcodes: string[];
  supplier: string;
  rack_no: string;
  instructions: string;
  doctor: string;
  clinic: string;
  mfg_date: string;
  expiry_date: string;
  tax: number;
  unit: string;
}
