import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../common-service.service';
import { Constants } from '../constants';
import { Doctor, GlobalResponse } from '../models';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit {
  payment = Constants.PAYMENT_CASH;
  paymentAmount: number;
  public advanceAmount = 0;
  public grandTotal: number;
  public invoiceId: string;
  public doctor: Doctor;
  public navigateURL: string;
  public paymentType: string;
  public confirmCheckboxLabel = '';
  public checkboxSelection = false;
  pendingAmount: any;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    private commonService: CommonService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.paymentAmount = this.grandTotal - this.advanceAmount;
    this.pendingAmount = 0;
  }

  changeAmount() {
    if (Number(this.paymentAmount) + Number(this.advanceAmount) >= this.grandTotal) {
      this.paymentAmount = this.grandTotal - Number(this.advanceAmount);
      this.pendingAmount = 0;
    } else {
      this.pendingAmount = (this.grandTotal - Number(this.paymentAmount) - this.advanceAmount).toFixed(2);
    }
  }

  confirmPayment() {
    if (this.payment) {
      if (this.paymentAmount || this.paymentAmount >= 0) {
        this.blockUI.start();

        let req_obj = {};
        req_obj = {
          invoiceId: this.invoiceId,
          paymentMethod: this.payment,
          amount: this.paymentAmount
        };
        this.commonService.updateTodayCheckupPayment(this.doctor, req_obj).subscribe(
          (res: GlobalResponse) => {
            this.blockUI.stop();
            this.toastr.success(Constants.SUCCESS_PAYMENT_DONE);
            if (this.checkboxSelection && this.paymentType === 'bill') {
              this.dialogRef.close('doaction');
            } else {
              this.dialogRef.close(true);
            }
            if (this.navigateURL && this.navigateURL.length > 1) {
              this.router.navigate([this.navigateURL]);
            }
          },
          error => {
            this.blockUI.stop();
            this.dialogRef.close(true);
            this.toastr.error(error.message);
          }
        );
      } else {
        this.toastr.error(Constants.ERROR_CHOOSE_PAYMENT_AMOUNT);
      }
    } else {
      this.toastr.error(Constants.ERROR_CHOOSE_PAYMENT_AMOUNT);
    }
  }
}
