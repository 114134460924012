import { IHSN } from '../common';
import { Batch } from './batch';

export class Drug {
  _id: string;
  name: string;
  category: string;
  hsn?: IHSN;
  qty: number;
  price: number;
  tax?: number;
  selling_price?: number;
  remaining_quantity?: number;
  unit: string;
  status: string;
  updated_at: string;
  manufacturer: string;
  threshold_qty: number;
  supplier: string;
  instructions: string;
  batches: Batch[];
  barcodes?: string[];
}
