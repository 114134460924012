import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../../shared-module/common-service.service';
import { Constants } from '../../shared-module/constants';
import { CustomerRegister, DoctorRegister, UpdateDoctorProfileRequest } from '../../shared-module/models';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {
  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {}

  isDoctorLoggedIn = false;

  login(emailId: string, password: string) {
    const login_payload = {
      userName: emailId,
      password
    };
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_LOGIN_API, login_payload);
  }

  verifyPIN(pin: string, doctorId: string) {
    const payload = { pin };
    return this.httpClient.post(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_API + doctorId + Constants.VERIFY_PIN_API,
      payload
    );
  }

  getDoctorDetails(docId: string) {
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.DOCTOR_API + docId);
  }

  updateProfile(doctorPayload: UpdateDoctorProfileRequest) {
    return this.httpClient.put(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_API + this.commonService.doctorValue._id,
      doctorPayload
    );
  }

  updateDoctorSettings(settingPayload: any) {
    return this.httpClient.put(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        this.commonService.doctorValue._id +
        Constants.DOCTOR_SETTINGS_API,
      settingPayload
    );
  }

  fetchDoctorList() {
    return this.httpClient.get(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_API + this.commonService.doctorValue._id + Constants.DOCTOR_LIST_API
    );
  }

  changePassword(oldPassword: string, newPassword: string, doctorId: string) {
    const changePassword_payload = {
      newPassword,
      oldPassword,
      doctorId
    };
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_CHANGE_PASSWORD_API, changePassword_payload);
  }

  registerCustomer(reqObj: CustomerRegister) {
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_REGISTER_CUSTOMER_WITH_PET_API, reqObj);
  }

  updatePet(reqObj: any, customer_id: string, pet_id: string) {
    return this.httpClient.patch(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_CUSTOMER_API + customer_id + Constants.PET_API + pet_id,
      reqObj
    );
  }

  fetchPets(searchType: string, searchValue: string) {
    const params = new HttpParams().set(searchType, searchValue);
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.DOCTOR_FETCH_PETS_API, { params });
  }

  searchPets(searchValue: string) {
    const params = new HttpParams().set(Constants.QUERY_PARAMS_Q, searchValue);
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        this.commonService.doctorValue._id +
        Constants.SLASH_CLINIC_API +
        this.commonService.doctorValue.clinics[0].clinic._id +
        Constants.LIST_API,
      { params }
    );
  }

  updateTodayCheckupPayment(reqObj: any) {
    const body = {
      amount: reqObj.amount,
      paymentMethod: reqObj.paymentMethod
    };
    return this.httpClient.post(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        reqObj.doctorId +
        Constants.SLASH_CLINIC_API +
        reqObj.clinicId +
        Constants.SLASH_INVOICE_SLASH_API +
        reqObj.invoiceId +
        Constants.PAYMENT_API,
      body
    );
  }

  requestOTP(mobile_number: number) {
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_OTP_GENERATE_API, {
      phone_no: mobile_number
    });
  }

  getBreeds(type: string = 'dog') {
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.DOCTOR_BREEDS_API + type);
  }

  getSpecies() {
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.DOCTOR_PET_SPECIES_API);
  }

  fetchCustomerDetails(mobile: any) {
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.DOCTOR_CUSTOMER_DETAILS_API + mobile);
  }

  verifyOTP(mobile_number: string, otp: string, otp_id: string) {
    const verify_otp_payload = {
      phone_no: mobile_number,
      otp,
      otp_id
    };
    return this.httpClient.put(Constants.MAIN_ENV_URL + Constants.DOCTOR_OTP_VERIFY_API, verify_otp_payload);
  }

  uploadDoctorProfileImage(docId: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('profileImage', file, file.name);
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_UPLOAD_PROFILE_IMAGE_API + docId, formData);
  }

  uploadClinicLogoImage(docId: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('clinicLogo', file, file.name);
    return this.httpClient.put(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        this.commonService.doctorValue._id +
        Constants.UPLOAD_CLINIC_LOGO_API +
        this.commonService.doctorValue.clinics[0].clinic._id,
      formData
    );
  }

  uploadDoctorSignatureImage(docId: string, files: any) {
    const formData: FormData = new FormData();
    for (const file of files) {
      formData.append('digitalSignatureImage', file);
    }
    return this.httpClient.post(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_UPLOAD_DIGITAL_SIGNATURE_API + docId,
      formData
    );
  }

  transferPet(petId: string, body: any) {
    return this.httpClient.put(Constants.MAIN_ENV_URL + Constants.TRANSFER_PET_API + petId, body);
  }
}
