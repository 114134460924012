import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../shared-module/common-service.service';
import { Constants } from '../../shared-module/constants';
import { Doctor, GlobalResponse } from '../../shared-module/models';
import { DoctorService } from '../../shared-module/services/doctor.service';
import * as screenfull from 'screenfull';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // isLoggedIn: boolean = false;
  phoneToggle = false;
  clinicToggle = true;
  @Input() isLoggedIn: boolean;
  @Input() navLarge: boolean;
  doctorList: Doctor[] = [];
  headerDoctorList: Doctor[] = [];
  doctor_selected: any;
  doctor: Doctor = {} as Doctor;
  action: string;
  enableCheckup = false;
  staffFlag = false;
  @BlockUI() blockUI: NgBlockUI;
  fullScreen = false;

  constructor(
    private commonService: CommonService,
    private toastr: ToastrService,
    private doctorService: DoctorService,
    private router: Router
  ) {
    this.commonService.checkupDoctorSubject.subscribe((res: any) => {
      if (res) {
        if (res.name) {
          this.action = res.name;
        } else if (res.first_name && res.last_name) {
          this.action = (res.salutation ? res.salutation : 'Dr.') + ' ' + res.first_name + ' ' + res.last_name;
        }
      }
    });
  }

  ngOnInit() {
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        this.fullScreen = screenfull.isFullscreen;
      });
    }
    this.doctor = JSON.parse(localStorage.getItem('login'));
    this.doctorList = this.commonService.doctorList;
    if (this.doctor && this.doctor.plan && this.doctor.plan.features) {
      for (const item of this.doctor.plan.features) {
        if (item.feature && item.feature === Constants.STAFF_MANGEMENT && item.available) {
          this.staffFlag = true;
        }
      }
    }
    if (this.commonService.doctorValue) {
      this.isLoggedIn = true;
      this.phoneToggle = this.commonService.doctorValue.settings.phone_consultation_available;
      this.clinicToggle = this.commonService.doctorValue.settings.inclinic_available;
    }
    if (
      this.router.url.indexOf('home') > -1 ||
      this.router.url.indexOf('activate') > -1 ||
      this.router.url.indexOf('login') > -1 ||
      this.router.url.indexOf('clinic-registration') > -1 ||
      this.router.url.indexOf('recover') > -1
    ) {
      this.isLoggedIn = false;
    }
    if ((!this.doctorList || this.doctorList.length < 1) && this.staffFlag) {
      this.fetchStaff();
    } else {
      if (this.doctorList && this.doctorList.length > 0) {
        for (const item of this.doctorList) {
          if (item.name !== 'All') {
            this.headerDoctorList.push(item);
          }
        }
      } else {
        this.doctor.name =
          (this.doctor.salutation ? this.doctor.salutation : 'Dr.') +
          ' ' +
          this.doctor.first_name +
          ' ' +
          this.doctor.last_name;
        this.headerDoctorList.unshift(this.doctor);
        this.doctor_selected = this.doctor;
        this.commonService.checkupDoctorSubject.next(this.doctor);
        this.commonService.doctorList = this.headerDoctorList;
      }
    }
  }
  toggleFullScreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }

  checkIfFullscreen(): boolean {
    if (screenfull.isFullscreen) {
      return true;
    } else {
      return false;
    }
  }

  toggleDoctor(type: string) {
    const settingsPayload = {
      inclinic_available: this.clinicToggle,
      phone_consultation_available: this.phoneToggle
    };
    this.blockUI.start();
    this.doctorService.updateDoctorSettings(settingsPayload).subscribe(
      (res: GlobalResponse) => {
        this.blockUI.stop();
        if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
          if (type === 'phone') {
            if (this.phoneToggle) {
              this.toastr.success(Constants.SUCCESS_DOCTOR_CALL_TOGGLE1);
            } else {
              this.toastr.success(Constants.SUCCESS_DOCTOR_CALL_TOGGLE);
            }
          } else {
            this.toastr.success('Settings Updated');
          }
          localStorage.removeItem('login');
          localStorage.setItem('login', JSON.stringify(res.result));
          this.commonService.setCurrentUserValue(res.result);
        }
      },
      error => {
        this.blockUI.stop();
        this.toastr.error(error);
      }
    );
  }

  fetchStaff() {
    this.blockUI.start();
    this.commonService.fetchDoctorList().subscribe(
      (res: GlobalResponse) => {
        this.blockUI.stop();
        if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
          this.headerDoctorList = res.result.doctor;
          this.headerDoctorList.unshift(this.doctor);
          this.doctor_selected = this.doctor;
          this.commonService.checkupDoctorSubject.next(this.doctor);
          if (this.headerDoctorList && this.headerDoctorList.length > 0) {
            this.commonService.doctorList = this.headerDoctorList;
            for (const item of this.headerDoctorList) {
              item.name = (item.salutation ? item.salutation : 'Dr.') + ' ' + item.first_name + ' ' + item.last_name;
            }
          }
          this.action = this.doctor.name;
        }
      },
      error => {
        this.blockUI.stop();
        this.toastr.error(error);
      }
    );
  }

  doctorChanged(item: Doctor) {
    if (item) {
      this.action = item.name;
      this.blockUI.start();
      this.commonService.checkupDoctorSubject.next(item);
      this.blockUI.stop();
    }
  }
}
