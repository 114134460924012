import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CommonService } from '../../shared-module/common-service.service';
import { Constants } from '../../shared-module/constants';
import { LoginResponse } from '../../shared-module/models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private commService: CommonService,
    private router: Router
  ) {}

  login(username: string, password: string) {
    return this.http
      .post<any>(Constants.MAIN_ENV_URL + Constants.DOCTOR_LOGIN_API, { username, password }, { withCredentials: true })
      .pipe(
        map((data: LoginResponse) => {
          // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
          if (data && data.http_code === Constants.STATUS_200 && data.result.doctor && data.result.token) {
            localStorage.setItem('login', JSON.stringify(data.result.doctor));
            localStorage.setItem('token', JSON.stringify(data.result.token));
            this.commService.setCurrentUserValue(data.result.doctor);
            this.commService.setTokenValue(data.result.token);
            return data.result.doctor;
          } else {
            return this.router.parseUrl('login');
          }
        })
      );
  }

  logout() {
    localStorage.removeItem('login');
    localStorage.removeItem('token');
    this.commService.doctorList = [];
    this.commService.setCurrentUserValue(null);
    this.commService.setTokenValue(null);
  }
}
