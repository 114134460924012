<!-- <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="dialogRef.close(false)">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button> -->

<h1 mat-dialog-title class="text-center blueHeader dialogHeader text-white">{{ headerMessage }}</h1>
<mat-dialog-content class="text-center mb-4">{{ statusMessage }}</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button mat-button class="mat-raised-button btn turquoiseBtn btnWidth" (click)="performAction()">OK</button>
  </div>
</mat-dialog-actions>
