import { Clinic, Doctor, Pet, Customer } from '../common';
import { AuthHistory } from './auth-history';
import { Vaccination } from './vaccination';
import { NextVaccination } from './next-vaccination';

export class Checkup {
  _id: string;
  discharge_details: {
    discharge_note: string;
    discharge_condition: string;
  };
  channel: string;
  type: string;
  status: string;
  remarks: string;
  consultation_fee: 100;
  veterinary_service_charges: 200;
  discount_percentage: 0.03125;
  discount: 100;
  discount_name: string;
  total_price: 3100;
  razorpay_order_id: string;
  created_by: string;
  updated_by: string;
  clinic: Clinic;
  doctor: Doctor;
  pet: Pet;
  customer: Customer;
  history: AuthHistory[];
  check_in_date: string;
  check_out_date: string;
  next_vaccinations: NextVaccination[];
  vaccinations: any[];
  disposables: any[];
  injectables: any[];
  medicines: any[];
  labs: any[];
  surgeries: any[];
  other_services: any[];
  medical_histories: any[];
  consumables: any[];
  follow_ups: any[];
  next_checkup_date: string;
  created_at: string;
  updated_at: string;
}
