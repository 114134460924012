import { Breed } from '../pet/breed';
import { Customer } from './customer';

export class Pet {
  name: string;
  dob: string;
  profile_image_url: string;
  birth_certificate_url: string;
  _id: string;
  species: string;
  sex: string;
  breed: Breed;
  customer: Customer;
  petid: string;
  pet_no: string;
  desexing: string;
  color: string;
  status: any;
}
