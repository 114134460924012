import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.scss']
})
export class StatusDialogComponent {
  public statusMessage: string;
  public headerMessage = 'Notification';
  public navigateToURL: string;

  constructor(
    public dialogRef: MatDialogRef<StatusDialogComponent>,
    private router: Router
  ) {}

  performAction() {
    this.dialogRef.close(true);
    if (this.navigateToURL && this.navigateToURL.length > 1) {
      this.router.navigate([this.navigateToURL]);
    }
  }
}
