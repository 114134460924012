export class MedicalHistory {
  _id: string;
  purpose_of_visit: string;
  weight: 45;
  temperature: 45;
  respiratory_rate: 44;
  heart_rate: 44;
  pulse: 44;
  others: any;
  health: string;
  pet_image: string;
  visible: boolean;
  status: string;
  created_by: string;
  updated_by: string;
  checkup: string;
  clinic: string;
  doctor: string;
  pet: string;
  customer: string;
  history: [
    {
      author: string;
      comment: string;
      _id: string;
      date: string;
      action: string;
    }
  ];
  created_at: string;
  updated_at: string;
}
