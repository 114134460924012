<div style="text-align: center">
  <div>
    <webcam
      [height]="500"
      [width]="500"
      [trigger]="triggerObservable"
      (imageCapture)="handleImage($event)"
      *ngIf="showWebcam"
      [allowCameraSwitch]="allowCameraSwitch"
      [switchCamera]="nextWebcamObservable"
      [videoOptions]="videoOptions"
      (cameraSwitched)="cameraWasSwitched($event)"
      (initError)="handleInitError($event)"
    >
    </webcam>
    <br />
    <div class="d-inline-block actionBtn takeCamera" (click)="triggerSnapshot()" title="Take Picture">
      <span class="fa fa-camera"></span>
    </div>
  </div>
</div>
