export class BillingDashboard {
  earnedByClinic: number;
  earnedByPhone: number;
  petCaredCount: number;
  phoneConsultationCount: number;
  totalBalance: number;
  totalAmountPaid: number;
  totalEarning: number;
  paidToken: number;
  earnedByToken: number;
  paidInClinic: number;
  paidByApp: number;
}
