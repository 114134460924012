<div class="row">
  <div class="col-md-12 top">
    <span>Do you need help?</span>
  </div>
  <div class="col-md-12 second pt-4">
    <span>Choose the option most appropriate for you.</span>
  </div>
  <div class="col-md-10 offset-md-2 pt-5 mt-5">
    <div class="row">
      <div class="col-md-4 phoneEmail pt-3 ml-5">
        <i class="fa fa-phone"></i>
        <h5 class="pt-3">Phone</h5>
        <a href="tel:8884543595">8884543595</a>
        <p>Phone support is available between 10 AM - 8 PM.</p>
      </div>
      <div class="col-md-4 phoneEmail pt-3 ml-5">
        <i class="fa fa-envelope"></i>
        <h5 class="pt-3">Email</h5>
        <a href="mailto:support@pawsnme.com">support&#64;pawsnme.com</a>
        <p>Your queries will be answered via email.</p>
      </div>
    </div>
  </div>
</div>
