export class Report {
  _id: string;
  name: string;
  remarks: string;
  price: number;
  url: string;
  visible: boolean;
  checkup: string;
  clinic: string;
  doctor: string;
  pet: string;
}
