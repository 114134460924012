import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../constants';
import { GlobalResponse, UploadFile } from '../models';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonService } from '../common-service.service';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html'
})
export class UploadDialogComponent implements OnInit {
  fileUploaded: UploadFile[] = [];
  public uploadedFiles: any[] = [];
  public oldUploadedFile: File = null;
  public oldUploadedFileList: any[] = null;
  public uploadButtonTitle = '';
  public headerTitle = 'Upload Files';
  public cancelButtonTitle = '';
  public submitButtonTitle = '';
  public uploadButtonText = 'Upload Attachment';
  public entityType: string;
  public entityId: string;
  public checkupId: string;
  isDisable = true;
  isFileUploaded = false;
  totalFileSize = 0;
  public multipleFiles = true;
  public fileTypeAccepted: string;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    private service: CommonService,
    @Inject(DOCUMENT) private document: any,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if (this.oldUploadedFile) {
      const varFileSize = this.oldUploadedFile[0].size / (1024 * 1000);
      this.fileUploaded.push({
        fileName: this.oldUploadedFile[0].name,
        fileSize: varFileSize,
        file: this.oldUploadedFile[0]
      });
      this.isFileUploaded = true;
      this.isDisable = false;
    }
    if (this.oldUploadedFileList && this.oldUploadedFileList.length > 0) {
      for (const item of this.oldUploadedFileList) {
        const varFileSize = item.size / (1024 * 1000);
        this.fileUploaded.push({
          fileName: item.name,
          fileSize: varFileSize,
          file: item
        });
        this.isFileUploaded = true;
        this.isDisable = false;
      }
    }
  }

  removeFile(file) {
    const index = this.fileUploaded.indexOf(file);
    this.fileUploaded.splice(index, 1);
    if (this.fileUploaded.length === 0) {
      this.isDisable = true;
    }
    this.toastr.success('Attachment deleted');
  }

  deleteFile(file) {
    if (this.entityType && (this.entityType === Constants.LAB_VALUE || this.entityType === Constants.SURGERY_VALUE)) {
      this.blockUI.start();
      const reqObj = {
        reportId: file._id
      };
      this.service.deleteFile(this.checkupId, this.entityType, this.entityId, reqObj).subscribe(
        (res: GlobalResponse) => {
          this.blockUI.stop();
          if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
            this.toastr.success(file.file_name + Constants.SUCCESS_DELETED);
            this.dialogRef.close(Constants.DELETE);
          }
        },
        error => {
          this.blockUI.stop();
          this.toastr.error(error);
        }
      );
    }
  }

  cancelClicked() {
    if (this.fileUploaded.length > 0) {
      this.toastr.error('Attachment upload cancelled');
    }
    this.dialogRef.close(false);
  }

  selectFile(event) {
    if (event.target.files.length > 0) {
      const filelist = event.target.files;
      for (const file of filelist) {
        const varFileSize = file.size / (1024 * 1000);
        this.fileUploaded.push({
          fileName: file.name,
          fileSize: varFileSize,
          file
        });
      }
      this.isFileUploaded = true;
      this.isDisable = false;
      event.target.value = null;
    }
  }

  uploadFile() {
    this.document.querySelector('#uploadBtn').click();
  }

  onUploadFiles(fileUploaded) {
    let isValidFileType = true;
    const filesToUpload = [];
    let errorCounter = 0;
    if (fileUploaded.length > 0) {
      this.totalFileSize = 0;
      for (const file of fileUploaded) {
        this.totalFileSize = this.totalFileSize + file.fileSize;
        filesToUpload.push(file.file);
      }
      if (this.fileUploaded.length > 10) {
        this.toastr.error('Reached maximum number of file limit.');
        errorCounter++;
      }
      if (this.totalFileSize > 25) {
        this.toastr.error('Maximum file Size reached.');
        errorCounter++;
      }
      for (const file of fileUploaded) {
        let validExt;
        if (this.fileTypeAccepted === 'Excel') {
          validExt = Constants.EXCEL_FILE_TYPE_SUPPORTED;
        } else if (this.fileTypeAccepted === 'Image') {
          validExt = Constants.IMAGE_FILE_TYPE_SUPPORTED;
        } else if (this.fileTypeAccepted === 'PDFImage') {
          validExt = Constants.PDF_IMAGE_FILE_TYPE_SUPPORTED;
        } else {
          validExt = Constants.FILE_TYPE_SUPPORTED;
        }
        const filePath = file.fileName;
        const getFileExt = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
        const pos = validExt.indexOf(getFileExt);
        if (pos < 0) {
          isValidFileType = false;
          break;
        }
      }
      if (isValidFileType === false) {
        if (this.fileTypeAccepted === 'Excel') {
          this.toastr.error('Excel format required');
        } else if (this.fileTypeAccepted === 'Image') {
          this.toastr.error('File Type not supported.');
        } else {
          this.toastr.error('File Type not supported.');
        }
        errorCounter++;
      }
      if (errorCounter === 0) {
        this.isDisable = false;
        this.dialogRef.close(filesToUpload);
        // this.toastr.success('Attachment uploaded Successfully');
      }
    }
  }
}
