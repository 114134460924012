import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import * as FileSaver from 'file-saver';
import { BehaviorSubject, Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import { Constants } from './constants';
import { Doctor, DrugRequest } from './models';
import { StatusDialogComponent } from './status-dialog/status-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private currentUserSubject: BehaviorSubject<Doctor>;
  public currentUser: Observable<Doctor>;
  private tokenSubj: BehaviorSubject<string>;
  public currentToken: Observable<string>;
  public errorMessageStatus = false;

  public checkupDoctorSubject: BehaviorSubject<Doctor>;
  public currentChkDoctor: Observable<Doctor>;

  statusDialogReg: MatDialogRef<StatusDialogComponent>;
  doctorList: Doctor[] = [];

  constructor(
    public dialog: MatDialog,
    private httpClient: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<Doctor>(JSON.parse(localStorage.getItem('login')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.checkupDoctorSubject = new BehaviorSubject<Doctor>(JSON.parse(localStorage.getItem('login')));
    this.currentChkDoctor = this.checkupDoctorSubject.asObservable();

    this.tokenSubj = new BehaviorSubject<string>(JSON.parse(localStorage.getItem('token')));
    this.currentToken = this.tokenSubj.asObservable();
  }

  public get tokenValue() {
    return this.tokenSubj.value;
  }

  public get checkupDocValue() {
    return this.checkupDoctorSubject.value;
  }

  public get doctorValue() {
    return this.currentUserSubject.value;
  }

  public setCurrentUserValue(value: Doctor) {
    this.currentUserSubject.next(value);
  }

  public setTokenValue(value: string) {
    this.tokenSubj.next(value);
  }

  openStatusDialog(statusMessage: string, URL?: string) {
    this.statusDialogReg = this.dialog.open(StatusDialogComponent, {
      disableClose: true
    });
    this.statusDialogReg.componentInstance.statusMessage = statusMessage;
    this.statusDialogReg.componentInstance.navigateToURL = URL;

    this.statusDialogReg.afterClosed().subscribe(result => {
      this.statusDialogReg = null;
    });
  }

  async _asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  openPrintPage(url: string) {
    const printWindow = window.open(url, '_blank');
    if (printWindow) {
      printWindow.onload = () => {
        printWindow.print();
      };
    }
  }

  addZero(i) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  generateFileName(startingName: string, fileExtension: string): string {
    const d = new Date();
    const mo = this.addZero(d.getMonth() + 1);
    const dt = this.addZero(d.getDate());
    const yr = this.addZero(d.getFullYear());
    const h = this.addZero(d.getHours());
    const m = this.addZero(d.getMinutes());
    const s = this.addZero(d.getSeconds());

    return startingName + dt + mo + yr + h + m + s + fileExtension;
  }

  fetchDoctorList() {
    return this.httpClient.get(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_API + this.doctorValue._id + Constants.DOCTOR_LIST_API
    );
  }

  deleteFile(checkupId: string, entityType: string, entityId: string, reqObj: any) {
    return this.httpClient.put(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        this.doctorValue._id +
        Constants.SLASH_CHECKUP_SLASH +
        checkupId +
        Constants.URL_DASH +
        entityType.toLowerCase() +
        Constants.URL_DASH +
        entityId +
        Constants.REMOVE_DOC_API,
      reqObj
    );
  }

  updateTodayCheckupPayment(doctor: any, reqObj: any) {
    return this.httpClient.post(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.SLASH_INVOICE_SLASH_API +
        reqObj.invoiceId +
        Constants.PAYMENT_API,
      reqObj
    );
  }

  updateStorePendingPayment(reqObj: any) {
    return this.httpClient.put(Constants.MAIN_ENV_URL + Constants.STORE_UPDATE_PENDING_PAYMENT_API, reqObj);
  }

  updateStorePayment(reqObj: any) {
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.STORE_UPDATE_PAYMENT_SUMMARY_API, reqObj);
  }

  addAdvancePayment(reqObj: any, checkupId: string) {
    return this.httpClient.post(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        this.doctorValue._id +
        Constants.SLASH_CHECKUP_SLASH +
        checkupId +
        Constants.ADVANCE_PAYMENT_API,
      reqObj
    );
  }

  updateAdvancePayment(reqObj: any, checkupId: string) {
    return this.httpClient.put(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        this.doctorValue._id +
        Constants.SLASH_CHECKUP_SLASH +
        checkupId +
        Constants.ADVANCE_PAYMENT_API,
      reqObj
    );
  }

  updateInvoiceCustomer(reqObj: any) {
    return this.httpClient.put(Constants.MAIN_ENV_URL + Constants.STORE_UPDATE_INVOICE_CUSTOMER_API, reqObj);
  }

  logOut() {
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_LOGOUT_API, {});
  }

  generateInvoice(reqObj: any, checkup_id: string) {
    // reqObj.clinicId = this.doctorValue.clinics[0].clinic._id;
    // reqObj.doctorId = this.doctorValue._id;
    return this.httpClient.post(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        reqObj.doctorId +
        Constants.SLASH_CLINIC_API +
        reqObj.clinicId +
        Constants.SLASH_CHECKUP_SLASH +
        checkup_id +
        Constants.SLASH_INVOICE,
      reqObj
    );
  }

  getData(doctor: Doctor, drugType: string, itemsPerPage: number = 0, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DRUG_LIST_API +
        doctor._id +
        Constants.URL_DASH +
        doctor.clinics[0].clinic._id +
        Constants.URL_DASH +
        drugType,
      { params }
    );
  }

  fetchHSNList(pageIndex: number, pageSize: number, q: string = '') {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, pageSize.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageIndex.toString())
      .set(Constants.QUERY_PARAMS_Q, q);
    return this.httpClient.get(
      Constants.MAIN_ENV_URL + Constants.HSN_SLASH_API + Constants.CLINIC_API + this.doctorValue.clinics[0].clinic._id,
      { params }
    );
  }

  getCustomerInfo(mobileNo: any, doctorID: string, clinicID: string, po_no: string = '') {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_CLINIC, clinicID)
      .set(Constants.QUERY_PARAMS_DOCTOR, doctorID)
      .set('PO', po_no)
      .set('phonenumber', mobileNo);
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.STORE_GET_STORE_CUSTOMER_API, { params });
  }

  getBarcodeData(search: any, type: string = '') {
    const params = new HttpParams().set(Constants.QUERY_PARAMS_SEARCH, search).set(Constants.QUERY_PARAMS_TYPE, type);
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.BARCODES_API, { params });
  }

  public exportAsExcelFile(json: any[], excelFileName: string, header?: any): void {
    const finalArray: string[][] = [header, json];
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: Constants.EXCEL_TYPE
    });
    FileSaver.saveAs(data, this.generateFileName(fileName + '_export_', Constants.EXCEL_EXTENSION));
  }

  getDrugList(drugType: string, pageNumber: number = 0, itemPerPage: number = 10, search: string = '') {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString())
      .set(Constants.LABEL_SEARCH, search);
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DRUG_LIST_API +
        this.doctorValue._id +
        Constants.URL_DASH +
        this.doctorValue.clinics[0].clinic._id +
        Constants.URL_DASH +
        drugType,
      { params }
    );
  }

  createDrug(item: DrugRequest) {
    item.doctor = this.doctorValue._id;
    item.clinic = this.doctorValue.clinics[0].clinic._id;
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DRUG_API, item);
  }

  createBatch(item: DrugRequest) {
    return this.httpClient.post(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        this.doctorValue._id +
        Constants.SLASH_CLINIC_API +
        this.doctorValue.clinics[0].clinic._id +
        Constants.SLASH_DRUG_SLASH_API +
        item.drugId +
        Constants.SLASH_BATCH_API,
      item
    );
  }

  createCustomer(custData: any) {
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_REGISTER_CUSTOMER_API, custData);
  }
}
