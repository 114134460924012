export class Plan {
  validity: {
    unitValue: number;
    unit: string;
  };
  name: string;
  status: string;
  _id: string;
  features: [
    {
      feature: string;
      available: true;
    }
  ];
  price: number;
  updated_at: string;
  created_at: string;
  updated_by: string;
  created_by: string;
}
