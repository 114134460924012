import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import jspdf from 'jspdf';
import autoTable from 'jspdf-autotable';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/shared-module/constants';
import { GlobalResponse } from 'src/app/shared-module/models';
import { NonSecureService } from '../non-secure.service';

@Component({
  selector: 'app-prescription-template',
  templateUrl: 'prescription-template.component.html',
  styleUrls: ['prescription-template.component.scss'],
  providers: [DatePipe]
})
export class PrescriptionTemplateComponent implements OnInit {
  mainData: any = {};
  prescription_id: string;
  base64ImageString: any;
  rowData: any[] = [];
  @BlockUI() blockUI: NgBlockUI;
  subTotal = 0;

  constructor(
    private titleService: Title,
    private toastr: ToastrService,
    private service: NonSecureService,
    private datepipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.prescription_id = this.route.snapshot.paramMap.get('prescription_id');
    if (this.prescription_id.length > 10) {
      this.fetchPrescriptionDetails();
    } else {
      this.router.navigate([Constants.PET_DASHBOARD_URL]);
    }
  }

  fetchPrescriptionDetails() {
    this.blockUI.start();
    this.service.fetchPrescriptionDetails(this.prescription_id).subscribe(
      (res: GlobalResponse) => {
        if (res && res.message && res.message === Constants.STATUS_SUCCESS && res.result) {
          this.mainData = res.result;
          this.subTotal = Math.floor(this.mainData.discount + this.mainData.amount);
          this.titleService.setTitle('Prescription ' + this.mainData.prescription_no);
          this.initData();
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.toastr.error(error);
      }
    );
  }

  initData() {
    this.createMainData(this.mainData.medicines, 'medicine');
    // this.createMainData(this.mainData.disposables, 'disposable', Constants.DISPOSABLE_VALUE);
    this.createMainData(this.mainData.injectables, 'injectable', Constants.INJECTABLE_VALUE);
    // this.createMainData(this.mainData.vaccinations, 'vaccination', Constants.VACCINATION_VALUE);
    if (this.mainData.clinic.logo_url && this.mainData.clinic.logo_url.length > 10) {
      this.getBase64ImageFromUrl(this.mainData.clinic.logo_url).then(base64 => {
        this.base64ImageString = base64;
      });
    }
    if (window.innerWidth <= 700) {
      setTimeout(() => {
        this.actionButton('print');
      }, 700);
    }
  }

  createMainData(listData: any, valueType: string, type: string = '') {
    for (const item of listData) {
      const tempItem = {} as any;
      if (item[valueType]) {
        if (type) {
          tempItem.medicine = item[valueType].name + ' - ';
          tempItem.medicine += type;
        } else {
          tempItem.medicine = item[valueType].name + ' - ';
          tempItem.medicine += item[valueType].category;
        }
        if (valueType === 'medicine') {
          tempItem.dose = item[valueType].timings
            ? item[valueType].timings.morning +
              ' - ' +
              item[valueType].timings.afternoon +
              ' - ' +
              item[valueType].timings.night
            : '';
        } else {
          tempItem.dose = '';
        }
        if (item[valueType].consume_days > 1) {
          tempItem.durationIns = item[valueType].consume_days + ' days';
        } else if (item[valueType].consume_days >= 0 && item[valueType].consume_days === 1) {
          tempItem.durationIns = item[valueType].consume_days + ' day';
        } else {
          tempItem.durationIns = '';
        }

        if (item[valueType].remarks) {
          tempItem.durationIns += item[valueType].remarks ? ' - ' + item[valueType].remarks : '';
        } else if (item[valueType].instructions) {
          tempItem.durationIns += item[valueType].instructions ? ' - ' + item[valueType].instructions : '';
        }

        let counter = 0;
        for (const tItem of this.rowData) {
          if (
            tItem.dose === tempItem.dose &&
            tItem.durationIns === tempItem.durationIns &&
            tItem.medicine === tempItem.medicine
          ) {
            counter++;
            break;
          }
        }

        if (counter === 0) {
          this.rowData.push(tempItem);
        }
      }
    }
    this.blockUI.stop();
  }

  async getBase64ImageFromUrl(imageUrl) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result);
        },
        false
      );
      reader.onerror = () => reject(this);

      reader.readAsDataURL(blob);
    });
  }

  actionButton(type: string) {
    const doc = new jspdf('p', 'mm', 'a4');

    doc.setFontSize(18);
    doc.setTextColor(40);
    if (this.mainData.clinic.logo_url && this.mainData.clinic.logo_url.length > 10) {
      doc.addImage(this.base64ImageString, 170, 5, 20 * 1.5, 20);
    }
    doc.line(11, 41, 200, 41);
    if (this.mainData.checkup && this.mainData.checkup.department && this.mainData.checkup.department === 'IPD') {
      doc.line(10, 76, 200, 76);
    } else {
      doc.line(10, 66, 200, 66);
    }

    const tempArray = [];
    for (const [index, item] of this.rowData.entries()) {
      const tempObj: any = {} as any;
      tempObj.index = index + 1;
      tempObj.medicine = item.medicine;
      tempObj.category = item.category;
      tempObj.dose = item.dose;
      tempObj.durationIns = item.durationIns;
      tempArray.push(tempObj);
    }

    autoTable(doc, {
      body: [
        [
          {
            content: this.mainData.clinic.name,
            styles: {
              halign: 'left',
              fontSize: 20
              // textColor: '#1e26e6'
            }
          }
        ],
        [
          {
            content: this.mainData.clinic.address,
            styles: {
              halign: 'left',
              cellPadding: 1,
              fontSize: 7,
              textColor: 'gray'
            }
          }
        ],
        [
          {
            content: 'Phone: ' + this.mainData.clinic.phone_no,
            styles: {
              halign: 'left',
              cellPadding: 1,
              fontSize: 8
            }
          }
        ]
      ],
      theme: 'plain'
    });
    const headerDetailsArrayRow1 = [
      {
        content: 'Pet Name: ' + this.mainData.pet.name,
        styles: {
          halign: 'left'
        }
      },
      {
        content: 'Owner Name: ' + this.mainData.customer.first_name + ' ' + this.mainData.customer.last_name,
        styles: {
          halign: 'left'
        }
      },
      {
        content: 'Date: ' + this.datepipe.transform(this.mainData.prescription_date, 'dd-MM-yyyy'),
        styles: {
          halign: 'left'
        }
      }
    ];
    const headerDetailsArrayRow2 = [
      {
        content: 'Pet ID: ' + this.mainData.pet.pet_id,
        styles: {
          halign: 'left'
        }
      },
      {
        content: 'Owner Phone No: ' + this.mainData.customer.phone_no,
        styles: {
          halign: 'left'
        }
      },
      {
        content: 'Prescription No: ' + this.mainData.prescription_no,
        styles: {
          halign: 'left'
        }
      }
    ];
    const headerDetailsArrayRow3 = [
      {
        content: 'Department: ' + this.mainData.checkup.department,
        styles: {
          halign: 'left'
        }
      },
      {
        content:
          'Doctor Name: ' +
          (this.mainData.doctor.salutation ? this.mainData.doctor.salutation : 'Dr.') +
          ' ' +
          this.mainData.doctor.first_name +
          ' ' +
          this.mainData.doctor.last_name,
        styles: {
          halign: 'left'
        }
      },
      {
        content: 'Channel: ' + this.mainData.checkup.channel,
        styles: {
          halign: 'left'
        }
      }
    ];
    const headerDetailsArrayRow4 = [
      {
        content: 'Check In Date: ' + this.datepipe.transform(this.mainData.checkup.check_in_date, 'dd-MM-yyyy'),
        styles: {
          halign: 'left'
        }
      },
      {
        content: 'Check Out Date: ' + this.datepipe.transform(this.mainData.checkup.check_out_date, 'dd-MM-yyyy'),
        styles: {
          halign: 'left'
        }
      }
    ];
    const headerArray = [];
    headerArray.push(headerDetailsArrayRow1);
    headerArray.push(headerDetailsArrayRow2);
    headerArray.push(headerDetailsArrayRow3);
    if (this.mainData.checkup && this.mainData.checkup.department && this.mainData.checkup.department === 'IPD') {
      headerArray.push(headerDetailsArrayRow4);
    }
    autoTable(doc, {
      body: headerArray,
      theme: 'plain'
    });

    if (tempArray && tempArray.length > 0) {
      autoTable(doc, {
        head: [['#', 'Medicine', 'Dose', 'Duration-Instructions']],
        body: tempArray,
        styles: {
          cellWidth: 'wrap'
        },
        columns: [
          { header: '#', dataKey: 'index' },
          { header: 'Name', dataKey: 'medicine' },
          { header: 'Dose', dataKey: 'dose' },
          { header: 'Duration-Instructions', dataKey: 'durationIns' }
        ],
        theme: 'striped',
        headStyles: {
          fillColor: '#3b21db',
          fontSize: 8
        },
        columnStyles: {
          0: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          1: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          2: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          3: { halign: 'left', cellWidth: 'auto', fontSize: 8 }
        }
      });
    } else {
      autoTable(doc, {
        body: [
          [
            {
              content:
                'Prescription is unavailable for this visit as it has either been communicated orally by the doctor, or the purpose of the visit didn\'t warrant any prescription.',
              styles: {
                halign: 'center',
                cellWidth: 'auto',
                fontSize: 8
              }
            }
          ]
        ],
        theme: 'plain'
      });
    }

    if (
      this.mainData.purpose_of_visit &&
      this.mainData.purpose_of_visit.length > 0 &&
      this.mainData.purpose_of_visit[0] &&
      this.mainData.purpose_of_visit[0].medical_history
    ) {
      autoTable(doc, {
        body: [
          [
            {
              content: 'Vitals',
              styles: {
                halign: 'center',
                fontSize: 20,
                fontStyle: 'bold'
              }
            }
          ]
        ],
        theme: 'plain'
      });

      const vitalArray = [];
      const tempPOVArray1 = [
        {
          content: 'Weight(kg): ' + this.mainData.purpose_of_visit[0].medical_history.weight,
          styles: {
            halign: 'left',
            cellWidth: 75,
            fontSize: 8
          }
        },
        {
          content: 'Heart Rate: ' + this.mainData.purpose_of_visit[0].medical_history.heart_rate,
          styles: {
            halign: 'left',
            fontSize: 8
          }
        }
      ];

      const tempPOVArray2 = [
        {
          content: 'Temperature(Fahrenheit): ' + this.mainData.purpose_of_visit[0].medical_history.temperature,
          styles: {
            halign: 'left',
            cellWidth: 75,
            fontSize: 8
          }
        },
        {
          content: 'Pulse: ' + this.mainData.purpose_of_visit[0].medical_history.pulse,
          styles: {
            halign: 'left',
            fontSize: 8
          }
        }
      ];

      const tempPOVArray3 = [
        {
          content: 'Respiratory Rate: ' + this.mainData.purpose_of_visit[0].medical_history.respiratory_rate,
          styles: {
            halign: 'left',
            cellWidth: 75,
            fontSize: 8
          }
        },
        {
          content: 'Clinical Examination: ' + this.mainData.purpose_of_visit[0].medical_history.health,
          styles: {
            halign: 'left',
            fontSize: 8
          }
        }
      ];

      const tempPOVArray4 = [
        {
          content: 'Others: ' + this.mainData.purpose_of_visit[0].medical_history.others,
          styles: {
            halign: 'left',
            cellWidth: 75,
            fontSize: 8
          }
        }
      ];

      vitalArray.push(tempPOVArray1);
      vitalArray.push(tempPOVArray2);
      vitalArray.push(tempPOVArray3);
      if (this.mainData.purpose_of_visit[0].medical_history.others) {
        vitalArray.push(tempPOVArray4);
      }

      autoTable(doc, {
        body: vitalArray,
        theme: 'plain'
      });
    }

    const checkupNotesArray = [];
    if (
      this.mainData.checkup.next_checkup_date &&
      this.mainData.checkup.next_checkup_date != null &&
      this.mainData.checkup.next_checkup_date.length > 0
    ) {
      const nextCheckupDate = [
        {
          content:
            'Next Checkup Date: ' +
            this.datepipe.transform(this.mainData.checkup.next_checkup_date[0].next_checkup_date, 'dd-MM-yyyy'),
          styles: {
            halign: 'left',
            fontSize: 8
          }
        }
      ];
      checkupNotesArray.push(nextCheckupDate);
    }

    const doctorNotes1 = [
      {
        content: 'Doctor Notes:',
        styles: {
          halign: 'left',
          fontStyle: 'bold',
          fontSize: 8
        }
      }
    ];
    const doctorNotes2 = [
      {
        content: this.mainData.checkup.doctor_notes,
        styles: {
          halign: 'left',
          fontSize: 8
        }
      }
    ];

    if (this.mainData.checkup.doctor_notes && this.mainData.checkup.doctor_notes != null) {
      checkupNotesArray.push(doctorNotes1);
      checkupNotesArray.push(doctorNotes2);
    }
    // const signatureArray = [
    //   {
    //     content: this.mainData.checkup.doctor_notes,
    //     styles: {
    //       halign: 'left'
    //     }
    //   },
    // ];

    // autoTable(doc, ({
    //   didDrawCell: (signatureArray) => {
    //     console.log(signatureArray);

    //     // if (data.section === 'body' && data.column.index === 0) {
    //       // var base64Img = 'data:image/jpeg;base64,iVBORw0KGgoAAAANS...'
    //     doc.addImage(this.base64ImageString, 'JPEG', signatureArray.cell.x + 2, signatureArray.cell.y + 2, 10, 10)
    //     // }
    //   },
    // }))

    autoTable(doc, {
      body: checkupNotesArray,
      theme: 'plain'
    });

    autoTable(doc, {
      body: [
        [
          {
            content: 'Powered by PawsNme',
            styles: {
              halign: 'center',
              textColor: '#1e26e6',
              fontSize: 8
            }
          }
        ],
        [
          {
            content: 'This is Computer Generated Prescription, No Signature Required',
            styles: {
              halign: 'center',
              fontSize: 8
            }
          }
        ]
      ],
      theme: 'plain'
    });

    if (type === 'download') {
      return doc.save('Prescription_' + this.mainData.prescription_no + '.pdf');
    } else if (type === 'print') {
      doc.autoPrint({ variant: 'non-conform' });
      const blob = doc.output('blob');
      window.open(URL.createObjectURL(blob));
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (window.innerWidth <= 700) {
      setTimeout(() => {
        this.actionButton('download');
      }, 700);
    }
  }
}
