import { Injectable } from '@angular/core';
import { Doctor } from 'src/app/shared-module/models';
@Injectable()
export class AuthService {
  doctor: Doctor;
  constructor() {}
  public isAuthenticated(): boolean {
    if (localStorage.getItem('login')) {
      return true;
    } else {
      return false;
    }
  }
  public isAuthorized(data): boolean {
    this.doctor = JSON.parse(localStorage.getItem('login'));
    if (!data || !data.pageAccess || (data.pageAccess && data.pageAccess === 'All')) {
      return true;
    } else if (data.pageAccess === 'MainDoctor') {
      if (!this.doctor.subordinate_flag) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
