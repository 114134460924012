import { AuthHistory } from './auth-history';

export class CheckupHistory {
  fileName: string;
  _id: string;
  medical_history: {
    _id: string;
    purpose_of_visit: string;
    weight: number;
    temperature: number;
    respiratory_rate: number;
    heart_rate: number;
    pulse: number;
    others: number;
    health: string;
    pet_image: string;
    history: AuthHistory[];
  };
}
