export class Batch {
  _id: string;
  rack_no: string;
  qty: number;
  buying_price: number;
  selling_price: number;
  status: string;
  expiry_period: number;
  seller_name: string;
  expiry_date: string;
  mfg_date: string;
  batch_no: number;
  batchId: string;
}
