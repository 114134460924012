export class DoctorRegister {
  firstName: string;
  lastName: string;
  salutation: string;
  qualification: string;
  keySkills: string;
  emailId: string;
  mobileNumber: string;
  clinicName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  pincode: string;
}
