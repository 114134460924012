<app-headernonlgn></app-headernonlgn>
<div class="container pr-0 pl-0">
  <main class="row">
    <div class="content pl-0 main-content col-lg-12 col-md-12">
      <router-outlet #route="outlet"></router-outlet>
    </div>
  </main>
</div>

<app-footer></app-footer>
