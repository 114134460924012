import { Clinic } from '..';

export class UpdateDoctorProfileRequest {
  firstName: string;
  lastName: string;
  salutation: string;
  address: string;
  keySkills: string;
  qualification: string;
  mobileNumber: string;
  emailId: string;
  about: string;
  consultation_fee: number;
  clinics: [{ clinic: Clinic }];
}
