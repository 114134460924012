import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../../shared-module/constants';
import { AuthenticationService } from '../../shared-module/services/authentication.service';
import { NonSecureService } from '../non-secure.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  submitted = false;
  emailId = '';
  password = '';
  returnUrl: string;
  emailIdForgetPwd = '';
  showForgetPassword = false;
  showPassword = false;

  message = 'Welcome!';
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: NonSecureService,
    private authenticationServ: AuthenticationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.authenticationServ.logout();
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  gotoRegister() {
    // this.router.navigate([Constants.REGISTER_DOCTOR_URL]);
    window.open(Constants.CLINIC_REGISTRATION_URL, '_self');
  }

  login() {
    if (
      this.emailId &&
      this.emailId.length > 0 &&
      (this.validateEmail(this.emailId) || this.validateMobile(this.emailId))
    ) {
      if (this.password && this.password.length > 0) {
        if (this.password.length > 6) {
          this.blockUI.start();
          this.authenticationServ.login(this.emailId, this.password).subscribe(
            res => {
              if (res) {
                this.blockUI.stop();
                this.submitted = true;
                this.toastr.success('Log-in Successful');
                if (this.returnUrl && this.returnUrl.length > 1) {
                  this.router.navigateByUrl(this.returnUrl);
                } else {
                  this.router.navigate([Constants.PET_DASHBOARD_URL]);
                }
              }
            },
            error => {
              this.blockUI.stop();
              if (error && error !== '') {
                this.toastr.error(error);
              }
            }
          );
        } else {
          this.toastr.error('Invalid Password – Password length must be more than 6 Alphanumeric values');
        }
      } else {
        this.toastr.error('Incorrect Password');
      }
    } else {
      this.toastr.error('Invalid Email ID/Mobile Number');
    }
  }

  forgotPassword = value => {
    this.showForgetPassword = !value;
    this.emailIdForgetPwd = this.emailId;
  }

  resetPassword() {
    if (this.emailIdForgetPwd.length > 0 && this.validateEmail(this.emailIdForgetPwd)) {
      this.blockUI.start();
      this.service.forgotPassword(this.emailIdForgetPwd).subscribe(
        (res: any) => {
          this.blockUI.stop();
          if (res && res.message) {
            this.toastr.success('Link to reset your password has been sent to the registered email address');
            this.showForgetPassword = false;
          }
        },
        error => {
          this.blockUI.stop();
          if (error && error === 'The inputs does not match with our records') {
            this.toastr.error('Incorrect Email ID');
          } else {
            this.toastr.error('Password reset failed');
          }
        }
      );
    } else {
      this.toastr.error('Invalid Email ID');
    }
  }

  validateEmail = email => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateMobile = mobile => {
    const mo = /^\d{10}$/;
    return mo.test(mobile);
  }
}
