import { Component, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-qty-tax',
  templateUrl: './add-edit-qty-tax.component.html',
  styleUrls: ['./add-edit-qty-tax.component.scss']
})
export class AddEditQtyTaxComponent {
  public qty = 1;
  public remainingQty: number;
  public tax: number;
  public headerTitle: string;
  public itemName: string;
  public modelType = 'qty';

  constructor(
    public dialogRef: MatDialogRef<AddEditQtyTaxComponent>,
    private toastr: ToastrService
  ) {}

  onValueChange() {
    if (this.qty < 1) {
      this.qty = 1;
    } else {
      if (this.qty > this.remainingQty) {
        this.qty = this.remainingQty;
        this.toastr.warning('Available item in inventory is ' + this.qty);
      }
    }
  }

  confirm() {
    this.modelType === 'qty' ? this.dialogRef.close(this.qty) : this.dialogRef.close(this.tax);
  }

  @HostListener('document:keydown', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    this.onKeydown(event);
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.confirm();
    }
  }
}
