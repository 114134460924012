import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../shared-module/constants';
import { DoctorRegister } from '../shared-module/models';

@Injectable({
  providedIn: 'root'
})
export class NonSecureService {
  constructor(private httpClient: HttpClient) {}

  public states = [
    'Andaman and Nicobar Islands',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Chandigarh',
    'Dadra and Nagar Haveli and Daman and Diu',
    'Delhi (National Capital Territory of Delhi)',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Ladakh',
    'Lakshadweep',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Puducherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal'
  ];

  getStates(): string[] {
    return this.states;
  }

  forgotPassword(emailId: string) {
    const resetPassword_payload = {
      emailId
    };
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_FORGOT_PASSWORD_API, resetPassword_payload);
  }

  registerDoctor(doctorPayload: DoctorRegister) {
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_LEAD_API, doctorPayload);
  }

  fetchInvoiceDetails(invoice_id: string) {
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.INVOICE_API + invoice_id);
  }

  fetchStoreInvoiceDetails(invoice_id: string) {
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.STORE_INVOICE_API + invoice_id);
  }

  fetchPrescriptionDetails(prescription_id: string) {
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.PRESCRIPTION_API + prescription_id);
  }
}
